import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "finding-a-balanced-way-to-be-involved-in-my-teenagers-life"
    }}>{`Finding a balanced way to be involved in my teenager's life`}</h1>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m4/8.svg" alt="nurture hero image" mdxType="SingleImage" />
    <p>{`The teenage years are a time of many changes and parents can feel that the parenting game has changed. But some of the rules have stayed the same. It is still important to love them, care for them, and support them (as you always have). Staying involved in your teenager's life also helps you notice if they are experiencing any problems.`}</p>
    <p>{`The one thing you need to do is start letting them go...`}</p>
    <p><em parentName="p">{`But how do I let them go AND still stay connected?`}</em></p>
    <p><em parentName="p">{`Nurture Roots and Inspire Wings`}</em>{` provides tips on finding the right balance between staying connected with your teenager while also allowing them to 'leave the nest' and develop more independence as they grow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      